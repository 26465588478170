.property-priority {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 6px;
  height: 6px;
  background: var(--light2);
  border-radius: 6px;
  opacity: 1;
}

.property-priority__1 {
  background: var(--red);
}

.property-priority__2 {
  background: var(--orange);
}

.property-priority__3 {
  background: var(--blue);
}

.property-priority__4 {
  background: var(--light2);
}
