.property-form-drawer {
  .ant-drawer-content {
    overflow: visible !important;
    background: var(--body-bg);
  }

  .ant-drawer-content-wrapper {
    width: calc(100% - 265px) !important;
  }

  .ant-drawer-header {
    .ant-drawer-close {
      position: absolute;
      left: -50px;
      background: var(--white);
      border-radius: 50%;
      padding: 10px 12px;
    }
  }

  &__no-paddings {
    .ant-drawer-body {
      padding: 0;
    }
  }

  &__no-title .ant-drawer-header {
    padding: 0;

    .ant-drawer-close {
      top: 18px;
    }

    .ant-drawer-title {
      display: none;
    }
  }
}

.ps-drawer {
  .ant-drawer-mask {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .ant-drawer-content-wrapper {
    background: var(--white);
    box-shadow: 0 1px 9px -3px rgba(0, 0, 0, 0.25) !important;

    .ant-drawer-header {
    }

    .ant-drawer-body {
      padding: 30px;
    }
  }

  &__user-menu {
    .ant-drawer-content-wrapper {
      .ant-drawer-header {
        padding: 24px 30px 0;
        border-bottom: 0;
        position: relative;

        .ant-drawer-close {
          padding: 0;
          margin: 0;
          position: absolute;
          top: 24px;
          right: 30px;
        }

        .ant-drawer-title {
          font-weight: 500;
          font-size: 1.5rem;
          line-height: 1;
          color: var(--gray-dark);
        }
      }
    }
  }
}

.drawer-width-400 .ant-drawer-content-wrapper {
  width: 400px !important;
}
