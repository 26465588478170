.notes {
  .note {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--light3);

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }

    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      margin-bottom: 8px;

      .data {
        padding-right: 30px;
      }

      .actions {
      }
    }

    .noteDescription {
      padding: 0;

      p {
        margin: 4px;
      }

      ul,
      ol {
        li {
          margin-bottom: 4px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
