.defaultTable {
  display: block;
  overflow-x: auto;

  table {
    width: 100%;

    thead,
    tbody {
      tr {
        th,
        td {
          padding: 0.75rem 1rem;
        }
      }
    }

    thead {
      tr {
        th {
          color: var(--light2);
          font-size: 0.9rem;
          text-transform: uppercase;
          font-weight: 600;
          letter-spacing: 0.1rem;
          cursor: default;
        }
      }
    }

    tbody {
      tr {
        transition: all 0.3s ease;

        &:hover {
          background: var(--light4);
          transition: all 0.3s ease;
        }

        td {
          font-weight: 400;
          font-size: 1rem;
          color: var(--gray-dark);
          line-height: 1.1rem;
        }
      }
    }
  }
}
