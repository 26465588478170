.pageTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-bottom: 30px;

  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 30px;

    h1 {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 500;
      color: var(--dark);
      font-size: 1.25rem;
      line-height: 1.2;
    }

    .breadcrumbs {
      margin-left: 1rem;

      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        li {
          padding-left: 7px;

          &:after {
            content: '•';
            padding-left: 0.5rem;
            color: var(--gray);
          }

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            &:after {
              display: none;
            }
          }

          a,
          span {
            user-select: none;
            color: var(--gray);
            font-weight: 500;
            font-size: 0.925rem;
            line-height: 1;
            text-decoration: none;
            outline: none;
          }

          a:hover {
            color: var(--primary);
          }

          span {
            cursor: default;
          }
        }
      }
    }
  }
}
