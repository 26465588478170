.propertyConversation {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .message {
      margin-bottom: 32px;
      width: 80%;

      .header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 6px;

        .name {
          margin-right: 6px;
          font-size: 15px;
          font-weight: 600;
          color: var(--dark);
        }

        .date {
          font-size: 12px;
          font-weight: 400;
          color: var(--light0);
        }
      }

      .body {
        background: #f8f5ff;
        border-radius: 6px;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        padding: 16px;
        color: var(--dark);
      }
    }

    .message[data-direction='in'] {
      margin-left: auto;

      .header {
        justify-content: flex-end;

        .name {
          margin-right: 0;
          margin-left: 6px;
          order: 2;
        }
      }

      .body {
        background: #f1faff;
      }
    }
  }
}
