@import 'buttons';
@import 'form';
@import 'drawer';
@import 'pagination';
@import 'modal';
@import 'tree';
@import 'popover';
@import 'tabs';
@import 'date-picker';

.ant-message .anticon {
  vertical-align: 0;
}
