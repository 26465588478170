.treeItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .name {
    color: var(--dark);
    font-weight: 500;
    font-size: 18px;
    line-height: 1;
    margin-right: 10px;
  }
}
