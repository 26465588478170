body {
  .ql-toolbar.ql-snow {
    border: 1px solid var(--secondary);
    border-top-left-radius: 0.42rem;
    border-top-right-radius: 0.42rem;
  }

  .ql-container.ql-snow {
    border: 1px solid var(--secondary);
    border-bottom-left-radius: 0.42rem;
    border-bottom-right-radius: 0.42rem;

    .ql-editor {
      min-height: 400px;

      p,
      ul li,
      ol li {
        font-size: 16px;
        line-height: 22px;
      }

      p {
        margin-bottom: 12px;
      }

      h1,
      h2,
      h3,
      h4 {
        margin-top: 20px;
        margin-bottom: 10px;
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}
