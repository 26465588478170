.ant-btn,
.btn {
  border: 1px solid transparent;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.42rem;
  height: auto;
  color: var(--gray-dark);
  box-shadow: none;

  svg {
    margin-right: 6px;
    margin-top: -2px;
  }
}

.btn-xsm {
  padding: 0.15rem 0.36rem;
}

.btn-sm {
  padding: 0.35rem 0.7rem;
}

.no-svg-padding svg {
  padding-right: 0;
  margin-right: 0;
}

.btn-link {
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent !important;
}

.btn-primary {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);

  &:hover {
    color: var(--white);
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
  }

  &__light {
    background: var(--primary-light);
    border-color: var(--primary-light);
    color: var(--primary);
  }

  &__simple {
    color: var(--gray);
    background-color: transparent;
    border-color: transparent;

    &:hover {
      color: var(--primary);
      background: var(--primary-light);
      border-color: var(--primary-light);
    }
  }
}

.btn-success {
  color: var(--white);
  background-color: var(--success);
  border-color: var(--success);

  &:hover {
    color: var(--white);
    background-color: var(--success-hover);
    border-color: var(--success-hover);
  }

  &__light {
    background: var(--success-light);
    border-color: var(--success-light);
    color: var(--success);
  }

  &__simple {
    color: var(--gray);
    background-color: transparent;
    border-color: transparent;

    &:hover {
      color: var(--success);
      background: var(--success-light);
      border-color: var(--success-light);
    }
  }
}

.btn-danger {
  color: var(--white);
  background-color: var(--danger);
  border-color: var(--danger);

  &:hover {
    color: var(--white);
    background-color: var(--red);
    border-color: var(--red);
  }

  &__light {
    background: var(--danger-light);
    border-color: var(--danger-light);
    color: var(--danger);
  }

  &__simple {
    color: var(--gray);
    background-color: transparent;
    border-color: transparent;

    &:hover {
      color: var(--danger);
      background: var(--danger-light);
      border-color: var(--danger-light);
    }
  }
}

.btn-warning {
  color: var(--white);
  background-color: var(--warning);
  border-color: var(--warning);

  &:hover {
    color: var(--white);
    background-color: var(--red);
    border-color: var(--red);
  }

  &__light {
    background: var(--warning-light);
    border-color: var(--warning-light);
    color: var(--warning);
  }

  &__simple {
    color: var(--gray);
    background-color: transparent;
    border-color: transparent;

    &:hover {
      color: var(--warning);
      background: var(--warning-light);
      border-color: var(--warning-light);
    }
  }
}
