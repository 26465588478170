:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #7e8299;
  --gray-dark: #3f4254;
  --primary: #3699ff;
  --primary-light: #e1f0ff;
  --primary-hover: #187de4;
  --secondary: #e4e6ef;
  --success: #1bc5bd;
  --success-hover: #23d5cc;
  --success-light: #c2f4f2;
  --info: #3699ff;
  --warning: #ffa800;
  --warning-light: #fff4de;
  --danger: #f64e60;
  --danger-light: #ffe2e5;
  --light: #f3f6f9;
  --light2: #b5b5c3;
  --light3: #ebedf3;
  --light4: #f9f9f9;
  --light0: #a1a5b7;
  --dark: #181c32;
  --white: #ffffff;
  --body-bg: #f0f5fa;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 13px !important;
  font-weight: 400;
  font-family: Poppins, Helvetica, 'sans-serif';
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--gray-dark);
}

body {
  background: var(--body-bg);
}

html,
body,
#root {
  height: 100%;
}

a {
  color: var(--primary);
  text-decoration: none;
  outline: none;
  transition: all 0.3s ease;

  &:hover {
    color: var(--primary-hover);
    transition: all 0.3s ease;
  }
}

.fw-500 {
  font-weight: 500;
}

.span-coma {
  span {
    &:after {
      content: ', ';
    }

    &:last-child {
      &:after {
        content: '';
      }
    }
  }
}

.f-size {
  font-size: 1rem;
}

.f-size-2 {
  font-size: 2rem;
}

.f-size-3 {
  font-size: 3rem;
}
