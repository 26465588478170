.propertyItem {
  position: relative;

  .item {
    .itemWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;

      .image {
        position: relative;
        flex: 0 0 140px;
        width: 140px;
        height: 140px;
        background: var(--primary-light);
        overflow: hidden;
        border-radius: 0.42rem;
        margin-right: 16px;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 200px;
          width: auto;
        }
      }

      .contentWrapper {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
        flex: 0 0 calc(100% - 156px);

        .content {
          flex: 0 0 70%;
          padding-right: 16px;

          h2 {
            margin-top: 0;
            font-size: 1.25rem;
            font-weight: 500;
            height: 24px;
            color: var(--dark);
          }

          .info {
            margin: 8px 0;

            ul {
              padding: 0;
              margin: 0;
              list-style-type: none;

              li {
                color: var(--light1);
                font-weight: 500;
                font-size: 1rem;
                margin-bottom: 8px;

                svg {
                  font-size: 1.2rem;
                  margin-right: 6px;
                  color: var(--primary);
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }

        .actions {
          flex: 0 0 30%;
          margin-left: auto;
          text-align: right;
        }
      }
    }
  }

  .itemFooter {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid var(--light3);

    .footerWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;

      .priceWrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        margin-right: 24px;

        .icon {
          font-size: 26px;
          margin-right: 6px;
          color: var(--light2);
        }

        .priceContent {
          h5 {
            margin-top: 0;
            margin-bottom: 6px;
            font-size: 0.825rem;
            font-weight: 600;
            color: var(--gray-dark);
            line-height: 1;
          }

          h3 {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 1.15rem;
            font-weight: 700;
            color: var(--dark);
            line-height: 1;
          }
        }
      }

      .priceWrapperHighLight {
        @extend .priceWrapper;

        .icon,
        .priceContent h5,
        .priceContent h3 {
          color: var(--success);
        }
      }

      .data {
        margin-left: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
      }
    }
  }
}
