.imageUploader {
  margin-bottom: 20px;

  .uploaderWrapper {
    position: relative;
    width: 100%;
    height: 100px;
    z-index: 1;

    input[type='file'] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 99;
    }

    .uploader {
      width: 100%;
      height: 100px;
      padding: 10px;
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 1px dashed var(--secondary);
      border-radius: 0.42rem;
      z-index: 2;

      .icon {
        font-size: 30px;
        line-height: 1;
        margin-bottom: 10px;
        color: var(--dark);
      }

      .placeholder {
        font-size: 14px;
        line-height: 1;
        color: var(--dark);
      }
    }
  }
}

.image {
  position: relative;
  width: 100%;
  height: 200px;
  z-index: 1;
  border-radius: 0.42rem;
  margin-top: 20px;
  overflow: hidden;

  .controls {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;

    > div {
      cursor: pointer;
    }

    .delete {
      color: var(--danger);
    }

    .main {
      color: var(--primary);
    }

    .nonMain {
      color: var(--secondary);
    }
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 250px;
  }
}
