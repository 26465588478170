.fileItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 16px;

  .icon {
    margin-right: 14px;
    color: var(--primary);
    background: var(--primary-light);
    border-radius: 50%;
    padding: 6px;
    width: 34px;
    height: 34px;
    text-align: center;

    svg {
      margin-top: -2px;
    }
  }

  .name {
    margin-right: 8px;
  }

  .actions {
    margin-left: auto;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
