.ant-picker {
  padding: 0.65rem 1rem;
  color: var(--gray-dark);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  height: calc(1.5em + 1.3rem + 2px);
  border: 1px solid var(--secondary);
  border-radius: 0.42rem;
  width: 100%;
}
