.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--white);
  background-clip: border-box;
  border-radius: 0.42rem;
  box-shadow: 0 0 30px 0 rgba(82, 63, 105, 0.05);
  border: 0;
  overflow: hidden;

  .cardHeader {
    width: 100%;
    padding: 0.75rem 2.25rem;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    // min-height: 70px;
    background-color: transparent;
    border-bottom: 1px solid var(--light3);

    .cardTitle {
      margin: 0.5rem;

      h3 {
        font-weight: 500;
        font-size: 1.275rem;
        color: var(--dark);
        margin: 0 0.75rem 0 0;
        line-height: 1.2;
      }

      h5 {
        margin: 0 0.75rem 0 0;
        padding-top: 0.5rem;
        line-height: 1;
        font-size: 0.925rem;
        color: var(--gray);
      }
    }
  }

  .cardContent {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 2rem 2.25rem;
  }
}
