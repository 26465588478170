.leadDetailsBlock {
  .blockWrapper {
    margin-bottom: 16px;
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: 0;
      width: 100%;
      height: 1px;
      background: var(--light3);
      z-index: 2;
    }

    h3 {
      width: auto;
      margin-top: 0;
      margin-bottom: 16px;
      font-size: 18px;
      line-height: 1;
      text-transform: uppercase;
      color: var(--light2);
      font-weight: 600;
      background: #fff;
      padding-right: 15px;
      position: relative;
      z-index: 3;
      display: inline-block;
    }

    h4 {
      width: auto;
      margin-top: 0;
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 1;
      text-transform: uppercase;
      color: var(--gray-dark);
      font-weight: 600;
      background: #fff;
      padding-right: 15px;
      position: relative;
      z-index: 3;
      display: inline-block;
    }

    .label {
      margin-bottom: 6px;
      text-transform: uppercase;
      color: var(--light2);
      font-weight: 500;
      line-height: 1;
      font-size: 16px;
    }

    .value {
      margin-bottom: 0;
      font-weight: 400;
      line-height: 1;
      font-size: 16px;
      color: var(--dark);

      strong,
      span {
        font-weight: 400;
      }
    }
  }
}
