.propertyDetails {
  padding: 0;
  margin: 0;

  .details {
    //background: var(--white);
    //padding: 24px;
    //box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);

    .head {
      margin-bottom: 28px;

      h1 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 36px;
        line-height: 1;
        color: var(--dark);
        font-weight: 700;
        letter-spacing: 0.01px;

        sup {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    .propertyFields {
      padding: 0;

      .label {
        font-size: 16px;
        line-height: 36px;
        color: var(--light2);
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 0;
      }

      .value {
        font-size: 16px;
        color: var(--gray-dark);
        font-weight: 400;
        margin-bottom: 0;

        p,
        ul,
        ol {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
