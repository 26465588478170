.ant-modal-content {
  position: relative;
  background-color: var(--white);
  background-clip: padding-box;
  border: 0;
  border-radius: 0.42rem;
  pointer-events: auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  overflow: hidden;

  .anticon {
    vertical-align: 0;
  }

  .ant-modal-header {
    color: var(--dark);
    border-bottom: 1px solid var(--light3);
  }
}
