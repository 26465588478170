.dark-color {
  color: var(--dark) !important;
}

.primary-color {
  color: var(--primary) !important;
}

.success-color {
  color: var(--success) !important;
}

.warning-color {
  color: var(--warning) !important;
}

.danger-color {
  color: var(--danger) !important;
}

.purple-color {
  color: var(--purple) !important;
}
