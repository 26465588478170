.DashboardWrapper {
  position: relative;

  .Dashboard {
    // padding-right: 364px;
  }
}

.Dashboard {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-areas: 'stats performance';
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 20px;

  .stats {
    grid-area: stats;
  }

  .performance {
    grid-area: performance;
  }
}

.CardList {
  position: relative;
  height: 100%;

  .ListItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px dashed var(--light);

    .Info {
      color: var(--gray);
      font-size: 12px;
      line-height: 16px;
    }

    .Stat {
      margin-left: 10px;
      color: var(--dark);
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
}
