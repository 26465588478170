.uploadButton {
  position: relative;
  z-index: 1;
  cursor: pointer;

  input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 3;
    cursor: pointer;
  }

  .button {
    padding: 0.35rem 0.7rem;
    background: var(--primary);
    color: var(--white);
    border-radius: 0.42rem;
    font-size: 1rem;
    line-height: 1.5;
  }
}
