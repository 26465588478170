.userDrawer {
  .userInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;

    .avatar {
      margin-right: 16px;
      flex: 0 0 100px;
      height: 100px;
      background: #e1f0ff;
      border-radius: 0.25rem;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        height: auto;
      }
    }

    .info {
      h3 {
        margin-top: 0;
        margin-bottom: 0.6rem;
        font-weight: 500;
        font-size: 22px;
        line-height: 1.1;
        color: var(--gray-dark);
      }

      p {
        font-size: 13px;
        color: var(--blue);
        cursor: default;

        span {
          color: var(--gray);
        }
      }
    }
  }

  .profileMenu {
    padding-top: 16px;
    border-top: 1px dashed var(--light);

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          outline: none;
          text-decoration: none;
          display: block;
          width: 100%;

          .menuItem {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: nowrap;

            .icon {
              margin-right: 16px;
              font-size: 20px;
              flex: 0 0 40px;
              height: 40px;
              position: relative;
              background: var(--light);
              border-radius: 0.25rem;

              svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }

            .menuName {
              h4 {
                margin: 0;
                font-weight: 400;
                color: var(--gray-dark);
                font-size: 14px;
                line-height: 20px;
              }

              p {
                margin-bottom: 0;
                font-size: 12px;
                color: var(--gray);
              }
            }
          }

          &:hover .menuItem .menuName h4 {
            color: var(--blue);
          }
        }
      }
    }
  }
}
