.default-form {
  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    line-height: 1.2;

    > label {
      font-size: 1rem;
      font-weight: 400;
      color: var(--gray-dark);
    }
  }

  .ant-input {
    padding: 0.65rem 1rem;
    color: var(--gray-dark);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    height: calc(1.5em + 1.3rem + 2px);
    border: 1px solid var(--secondary);
    border-radius: 0.42rem;
  }

  .ant-input-affix-wrapper {
    padding: 0 1rem;
    color: var(--gray-dark);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    border: 1px solid var(--secondary);
    border-radius: 0.42rem;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: var(--primary);
    box-shadow: none;
  }

  .ant-input:focus,
  .ant-input-focused,
  .ant-input-number:focus,
  .ant-input-number-focused {
    border-color: var(--primary);
    box-shadow: none;
  }

  .ant-input-number {
    width: 100%;
    padding: 0.65rem 1rem;
    color: var(--gray-dark);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    height: calc(1.5em + 1.3rem + 2px);
    border: 1px solid var(--secondary);
    border-radius: 0.42rem;
    overflow: hidden;
  }

  .ant-input-number-input {
    height: 20px;
    padding: 0;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-color: var(--danger);
  }

  .ant-form-item-explain-error {
    color: var(--danger);
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid var(--secondary);
    border-radius: 0.42rem;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 0;
  }

  .ant-select-multiple .ant-select-selector {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ant-select-multiple .ant-select-selection-item-remove > .anticon {
    vertical-align: 0;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 20px;
    transition: all 0.3s;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--primary);
    box-shadow: none;
  }
}
