.ant-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: var(--gray-dark);

  .ant-pagination-item-active {
    font-weight: 500;
    background: var(--white);
    border-color: var(--primary);
  }

  li {
    button {
      .anticon {
        vertical-align: 0;

        svg {
          margin-top: -2px;
        }
      }
    }
  }
}
