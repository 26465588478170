.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--white);
  box-shadow: 0 0 40px 0 rgba(82, 63, 105, 0.1);
  z-index: 999;

  .headerWrapper {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;

    .rightMenu {
      .rightMenuWrapper {
        .userMenu {
          button {
            padding: 0;
            margin: 0;
            border: 0;
            box-shadow: none;
            outline: none;
            width: 30px;
            height: 30px;
            background: var(--light);
            border-radius: 0.25rem;

            img {
              max-width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}
