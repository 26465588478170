$defaultSidebarWidth: 265px;
$smallSidebarWidth: 70px;

.mainLayout {
  min-height: 100vh;
  position: relative;
  z-index: 1;

  .sidebar {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: $defaultSidebarWidth;
    height: 100%;
    background: linear-gradient(180deg, #040d21, #0e2655) !important;
    transition: all 0.3s ease;
    z-index: 3;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;

      .logo {
        color: var(--white);
        font-size: 1.5rem;
        line-height: 1;
        font-weight: 700;
      }

      .toggle {
        font-size: 22px;
        transition: all 0.3s ease;

        button {
          border: 0;
          padding: 0;
          margin: 0;
          background: transparent;
          box-shadow: none;

          svg {
            stroke: rgb(181, 181, 195);
            color: rgb(181, 181, 195);
          }
        }
      }

      .toggleOpen button svg {
        transform: rotateY(-180deg);
      }
    }

    .menuWrapper {
      overflow: hidden;
      margin-top: 30px;

      .menu {
        padding: 0 20px 20px;
        overflow: auto;
        overflow-x: hidden;
        max-height: calc(100vh - 100px);

        ul {
          padding: 0;
          margin: 0;
          list-style-type: none;

          > li {
            margin-bottom: 20px;

            > a {
              display: block;
              opacity: 1;
              visibility: visible;
              outline: none;
              text-decoration: none;
              user-select: none;
              color: var(--white);
              transition: all 0.3s ease;

              > .menuItem {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: nowrap;

                > .menuExpand,
                > .menuIcon {
                  margin-right: 1rem;

                  svg {
                    height: 20px;
                    width: 20px;
                    margin-top: -2px;
                    transition: all 0.3s ease;
                  }
                }

                > .menuName {
                  font-size: 14px;
                  line-height: 1;
                  font-weight: 600;
                }

                > .menuExpand {
                  margin-left: auto;
                  margin-right: 0;

                  svg {
                    height: 16px;
                    width: 16px;
                    transition: all 0.5s ease;
                  }
                }
              }

              &:hover {
                color: var(--blue);
              }
            }

            > ul {
              padding-top: 0;
              padding-left: 34px;
              height: 0;
              overflow: hidden;
              transition: all 0.3s ease;

              li {
                &:last-child {
                  margin-bottom: 0;
                }

                a {
                  outline: none;
                  text-decoration: none;
                  user-select: none;
                  font-size: 14px;
                  line-height: 1;
                  font-weight: 400;
                  color: var(--white);

                  &:hover {
                    color: var(--blue);
                  }
                }
              }
            }
          }

          > li.expanded {
            transition: all 0.3s ease;

            > a > .menuItem {
              > .menuExpand {
                svg {
                  transform: rotateX(-180deg);
                  transition: all 0.5s ease;
                }
              }
            }

            > ul {
              padding-top: 20px;
              height: auto;
              transition: all 0.3s ease;
            }
          }
        }
      }
    }
  }

  .sidebarSmall {
    overflow: visible;
    width: $smallSidebarWidth;
    transition: all 0.3s ease;

    .head {
      justify-content: center;

      .logo {
        display: none;
      }

      .toggle {
        font-size: 26px;
        transition: all 0.3s ease;
      }
    }

    .menuWrapper {
      overflow: visible;
      margin-top: 30px;

      .menu {
        overflow: visible;

        ul {
          > li {
            margin-bottom: 30px;
            position: relative;

            > a {
              display: block;

              > .menuItem {
                justify-content: center;

                > .menuExpand,
                > .menuIcon {
                  margin-right: 0;

                  svg {
                    height: 24px;
                    width: 24px;
                    transition: all 0.3s ease;
                  }
                }

                > .menuName {
                  opacity: 0;
                  visibility: hidden;
                  width: 0;
                }

                > .menuExpand {
                  display: none;
                }
              }

              &:hover {
                color: var(--blue);
              }
            }

            > ul {
              width: 0;
              opacity: 0;
              visibility: hidden;
              overflow: hidden;
              position: absolute;
              left: 100%;
              top: -16px;
              min-height: 50px;
              padding: 0;
              z-index: 1;
              transition: all 0.1s ease, opacity 0.3s ease;

              &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 20px;
                width: calc(100% - 20px);
                height: 100%;
                background: linear-gradient(180deg, #040d21, #0e2655) !important;
                z-index: 2;
              }

              li {
                position: relative;
                z-index: 3;

                &:last-child {
                  margin-bottom: 0;
                }

                a {
                  font-size: 16px;
                  color: var(--white);

                  &:hover {
                    color: var(--blue);
                  }
                }
              }
            }

            &:hover {
              > ul {
                opacity: 1;
                visibility: visible;
                width: 200px;
                height: auto;
                overflow: visible;
                padding: 20px 20px 20px 40px;
                transition: all 0.1s ease, opacity 0.3s ease;
              }
            }
          }
        }
      }
    }
  }

  .content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    position: relative;
    z-index: 2;

    main {
      padding: 100px 30px 30px;
      flex: 1 1 100%;

      .container {
        display: block;
        width: 100%;
        max-width: 1370px;
        margin: 0 auto;
      }
    }
  }

  .contentDefault {
    padding-left: $defaultSidebarWidth;
    transition: all 0.3s ease;

    header {
      padding-left: $defaultSidebarWidth;
      transition: all 0.3s ease;
    }
  }

  .contentSmall {
    padding-left: $smallSidebarWidth;
    transition: all 0.3s ease;

    header {
      padding-left: $smallSidebarWidth;
      transition: all 0.3s ease;
    }
  }

  .footer {
    padding: 20px 30px;
    background: var(--white);

    p {
      margin: 0;
      font-size: 13px;
      color: var(--gray-dark);
    }
  }
}

@media screen and (min-width: 1700px) {
  .mainLayout {
    .content {
      main {
        .container {
          display: block;
          width: 100%;
          max-width: 1920px;
          margin: 0 auto;
        }
      }
    }
  }
}
