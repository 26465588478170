.propertyDetails {
  .detail {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    p {
      color: var(--light1);
      font-weight: 500;
      font-size: 1rem;
      margin-bottom: 4px;
    }

    h5 {
      margin-top: 0;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 24px;
      color: var(--dark);
      text-transform: capitalize;
    }
  }
}
