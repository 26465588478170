.authLayout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .layoutWrapper {
    width: 100%;
    max-width: 500px;
    padding: 30px;

    h1 {
      margin-top: 0;
      margin-bottom: 2rem;
      font-size: 2rem;
      color: var(--dark);
      font-weight: 700;
    }
  }
}
